@use 'variables';

.dashboard {
    margin: 0 auto 60px auto;
    max-width: 1360px;
    &__title {
        margin: 20px 0;
        @include variables.for-tablet-landscape-up {
            margin: 30px 0;
        }
    }

    &__menu {
        &__container {
            position: relative;
            @include variables.for-tablet-landscape-up {
                display: flex;
                flex-direction: row-reverse;
            }
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            @include variables.for-tablet-landscape-up {
                flex: 0 0 120px;
            }
        }
        &__item {
            display: flex;
            align-items: center;
            position: relative;
            flex: 0 0 100%;
            cursor: pointer;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            line-height: 27px;
            margin: 10px 0px;
            padding: 0px 15px;
            height: 55px;
            @include variables.bold-font;
            &::before {
                position: absolute;
                content: '';
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                top: 21px;
                right: 30px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                justify-content: center;
                background-color: #e2e2e2;
                &::before {
                    display: none;
                }
            }

            &.open {
                background-color: #000;
                color: #fff;
                border-radius: 4px 4px 0px 0px;
                border: 1px solid #000;
                &::before {
                    top: 24px;
                    transform: rotate(225deg);
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                }
            }

            @include variables.for-tablet-landscape-up {
                margin: 0px;
                border-radius: 0px 4px 4px 0px;
                background-color: #e2e2e2;
                color: #000;
                height: 50px;
                padding: 12px 15px;
                border: 1px solid #e2e2e2;
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 65px;
        width: 100%;
        background-color: #fff;
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #e2e2e2;
        @include variables.for-tablet-landscape-up {
            position: relative;
            top: 0;
            display: flex;
            flex: 1 1 85%;
            width: auto;
            height: 50px;
            border-radius: 4px 0px 0px 4px;
            border-right: none;
        }

        &__item {
            padding: 14px 15px;
            cursor: pointer;
            line-height: 27px;
            @include variables.bold-font;
            &:hover {
                color: #00A053;
            }
            &.active {
                color: #00A053;
            }
            @include variables.for-tablet-landscape-up {
                border-right: 1px solid #e2e2e2;
                padding: 12px 18px;
            }
        }
    }

    &__information {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        @include variables.for-tablet-portrait-up {
            flex-direction: row;
        }
        h3 {
            margin-bottom: 30px;
        }
        @include variables.for-tablet-landscape-up {
            margin: 60px 0;
        }
        .left,
        .right {
            flex: 1 1 50%;
        }
        .left {
            margin-bottom: 30px;
            @include variables.for-tablet-portrait-up {
                margin-bottom: 0;
            }

            p {
                max-width: 600px;
                font-size: 16px;
                line-height: 27px;
            }
        }
        .form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .field {
                flex: 0 0 100%;
                margin-bottom: 15px;
                @include variables.for-tablet-portrait-up {
                    flex: 0 0 48%;
                }
                label {
                    display: block;
                    font-size: 0.875rem;
                    margin-bottom: 16px;
                    @include variables.bold-font;
                }
                input {
                    border-radius: 4px;
                    border: 1px solid #e2e2e2;
                    height: 40px;
                    padding-left: 15px;
                    width: 100%;
                }
            }
            .save {
                border-radius: 4px;
                border: 1px solid #e2e2e2;
                cursor: pointer;
                padding: 14px 18px;
                margin-top: 15px;
                @include variables.bold-font;
                &:hover {
                    background-color: #00A053;
                    color: #fff;
                }
            }
        }
    }

    .flex-menu {
        display: flex;
        width: 100%;
        .tabs-menu {
            width: 100%;
            .react-tabs__tab-list {
                display: flex;
                flex-direction: column;
                border-radius: 4px;
                display: flex;
                font-size: 0.875rem;
                .react-tabs__tab {
                    cursor: pointer;
                    line-height: 27px;
                    text-transform: uppercase;
                    padding: 14px 15px;
                    flex: 0 0 100%;
                    @include variables.bold-font;
                    &.react-tabs__tab--selected {
                        color: #00A053;
                    }
                }
                .log-out {
                    flex: 0 0 100%;
                    background: #f8f8f8;
                    border-left: 1px solid #e2e2e2;
                    border-right: none;
                    //margin-left: auto;
                    text-align: center;
                }
            }
            .image-blocks {
                .images {
                    margin-top: 35px;
                    @include variables.for-tablet-landscape-up {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .images-item {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        flex: 0 1 50%;
                        margin-top: 15px;
                        min-width: 300px;
                        max-width: 640px;
                        @include variables.for-tablet-landscape-up {
                            box-sizing: border-box;
                            padding: 10px;
                            padding-bottom: 0px;
                            max-width: 680px;
                        }

                        &.big {
                            flex: 0 1 50%;
                            max-width: 640px;
                            @include variables.for-tablet-landscape-up {
                                flex: 0 1 100%;
                                max-width: 100%;
                            }

                            .title {
                                max-width: 500px;
                            }
                        }

                        .overlay {
                            position: relative;

                            img {
                                border-radius: 10px;
                            }

                            &::before {
                                position: absolute;
                                content: '';
                                width: 100%;
                                height: 100%;
                                background: transparent
                                    linear-gradient(90deg, #000000 0%, #00000000 100%) 0% 0%
                                    no-repeat padding-box;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                border-radius: 10px;
                                opacity: 0.5;
                            }
                        }

                        .images-content {
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            padding: 15px;
                            width: 100%;
                            height: 100%;
                            @include variables.for-400Up-only {
                                padding: 20px;
                            }
                            @include variables.for-mediumUp-only {
                                padding: 40px;
                            }

                            .title {
                                flex: 1 1 100%;
                                max-width: 300px;
                                color: white;
                                h3 {
                                    font-size: 18px;
                                    line-height: 27px;
                                    @include variables.for-400Up-only {
                                        font-size: 27px;
                                        line-height: 35px;
                                    }
                                    @include variables.for-mediumUp-only {
                                        font-size: 30px;
                                        line-height: 40px;
                                    }
                                    @include variables.for-tablet-landscape-up {
                                        font-size: 24px;
                                        line-height: 27px;
                                    }
                                    @include variables.for-desktop-up {
                                        font-size: 30px;
                                        line-height: 40px;
                                    }
                                }
                            }

                            .button {
                                display: flex;
                                font-size: 16px;
                                @include variables.bold-font;
                                a {
                                    padding: 16px 20px 16px 20px;
                                    border: 1px solid #e2e2e2;
                                    color: #000000;
                                    border-radius: 4px;
                                    background-color: #ffffff;
                                    display: inline;
                                    transition: ease 0.1s;
                                    @include variables.for-smallUp-only {
                                        width: 100%;
                                        text-align: center;
                                    }
                                    @include variables.for-mediumUp-only {
                                        width: auto;
                                    }
                                }

                                a:hover {
                                    background-color: #00A053;
                                    color: #ffffff;
                                    border: 1px solid #00A053;
                                    transition: ease 0.1s;
                                }
                            }
                        }
                    }
                }
            }
            .recommendations {
                margin-top: 50px;
                h2 {
                    font-size: 1.875rem;
                    line-height: 2.5rem;
                    text-align: center;
                }
            }
        }
    }
    .Toastify__toast-container {
        top: 35%;
        .Toastify__toast {
            background: #00A053;
            color: #ffffff;
            font-size: 1rem;
            @include variables.bold-font;
            .Toastify__close-button {
                background: transparent !important;
                color: #ffffff;
                opacity: 1;
            }
        }
    }
}
